import React, { useState, useContext, useEffect } from "react";
import axiosInstance from "../helper/axios";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../components/UserContext";
import DataLoading from "../components/DataLoading";
import Trans from "../helper/Trans";

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorText, setErrorText] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [haveInstructions, setHaveInstructions] = useState(false);

  const { setTitle, setOperation, user, setUser, setPLink, siteInfo, appMsg } =
    useContext(UserContext);
  useEffect(() => {
    setTitle("");
    setOperation("");
    setPLink("");
  }, [setTitle, setOperation, setPLink]);

  let navigate = useNavigate();

  useEffect(() => {
    if (user !== null) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (siteInfo && siteInfo.instruction) {
      setHaveInstructions(true);
    }
  }, [siteInfo]);
  function handleUserChange(e) {
    setUsername(e.target.value);
  }
  function handlePassChange(e) {
    setPassword(e.target.value);
  }

  const loginApi = axiosInstance;

  function login(e) {
    setLoading(true);
    e.preventDefault();
    var formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    loginApi
      .post("/authenticate", formData)
      .then((res) => {
        if (res.status === 200) {
          window.localStorage.setItem("user", JSON.stringify(res.data));
          setUser(res.data);
        } else {
          setErrorText(Trans("Invalid email or password!"));
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setErrorText(Trans("Invalid email or password!"));
        } else {
          setErrorText(err.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createMarkup() {
    if (siteInfo) {
      let html = "<h4 class='text-danger'>" + Trans("Instructions") + "</h4>";
      html += siteInfo.instruction;

      return { __html: html };
    }
    return { __html: "" };
  }

  return (
    <div className="auth-layout-wrap">
      <div
        className="auth-content"
        style={
          haveInstructions
            ? { maxWidth: "600px", marginTop: "10px" }
            : { marginTop: "10px" }
        }
      >
        <div className="card o-hidden">
          <div className="site-login">
            <div className="p-4">
              <h3>{Trans("Applicant Login")}</h3>

              {appMsg === "" ? (
                <></>
              ) : (
                <div className="alert alert-danger" role="alert">
                  {appMsg}
                </div>
              )}
              <p>{Trans("Please fill out the following fields to login:")}</p>

              <div className="row">
                <div
                  className={haveInstructions ? "col-md-6 mb-3" : "col-md-12"}
                >
                  <form id="login-form" data-gtm-form-interact-id="0">
                    <input
                      type="hidden"
                      name="_csrf-backend"
                      value="MRNfdonA-Ltj9YsJwfnre48tEb55PYyzPOp3a5hZ7oJTZDMQ25mbjzrH2G6rqa053WRD3TRbteYLxy478i6NxA=="
                    />
                    <div className="form-group field-loginformstudent-username required">
                      <label htmlFor="loginformstudent-username">
                        {Trans("Email")}
                      </label>
                      <input
                        type="text"
                        id="loginformstudent-username"
                        className="form-control "
                        name="LoginFormStudent[username]"
                        autoFocus=""
                        aria-required="true"
                        data-gtm-form-interact-field-id="0"
                        aria-invalid="false"
                        onChange={handleUserChange}
                        value={username}
                      />

                      <div className="invalid-feedback"></div>
                    </div>
                    <i
                      className="login-eye"
                      id="login_eye"
                      style={{
                        backgroundImage: showPassword
                          ? 'url("/imgs/eye_close.svg")'
                          : 'url("/imgs/icon-preview.svg")',
                      }}
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    ></i>
                    <div className="form-group field-loginformstudent-password required">
                      <label htmlFor="loginformstudent-password">
                        {Trans("Password")}
                      </label>
                      <input
                        type={showPassword ? "input" : "password"}
                        id="loginformstudent-password"
                        className="form-control "
                        name="LoginFormStudent[password]"
                        aria-required="true"
                        data-gtm-form-interact-field-id="1"
                        aria-invalid="false"
                        onChange={handlePassChange}
                        value={password}
                      />

                      <div className="required">{errorText}</div>
                    </div>

                    <div className="form-group mt-3">
                      {loading ? (
                        <DataLoading loadTxt={Trans("Authenticating.....")} />
                      ) : (
                        <button
                          type="submit"
                          className="btn btn-primary"
                          name="login-button"
                          onClick={login}
                          style={{ width: "100%" }}
                        >
                          {Trans("Login")}
                        </button>
                      )}
                    </div>
                  </form>
                  <div></div>
                  <p className="text-center">
                    <Link
                      to="/resetpassword"
                      className="btn btn-outline-success float-left"
                    >
                      {Trans("Fogot Password")}
                    </Link>
                    <Link
                      to="/signup"
                      className="btn btn-outline-success float-right"
                    >
                      {Trans("Signup")}
                    </Link>
                  </p>
                </div>
                <div className="col-md-6 ">
                  <div className="pl-4">
                    <div dangerouslySetInnerHTML={createMarkup()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
